<script>
import Layout from '@layouts/login/login'
import i18n from '@src/i18n'
import ResetPassword from '@components/reset-password/reset-password'

import appConfig from '@src/app.config'

export default {
  page: {
    title: i18n.t('RESET_PASSWORD'),
    meta: [
      { name: 'description', content: `Reset password to ${appConfig.title}` },
    ],
  },
  components: { Layout, ResetPassword },
}
</script>

<template>
  <Layout>
    <ResetPassword />
  </Layout>
</template>
